/*
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-19 17:09:12
 * @LastEditors  : min
 * @LastEditTime : 2020-12-23 11:29:36
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import moment from "moment";

Vue.config.productionTip = false;
Vue.use(Antd);

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem("_user_token_")) {
    next();
  } else {
    console.log(sessionStorage.getItem("_user_token_"));
    if (to.path == "/") {
      next();
    } else {
      next("/");
    }
  }
});

//定义一个全局过滤器实现日期格式化
Vue.filter("dateformat", (input, fmtstring) => {
  //当input为时间戳时，需转为Number类型
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});

Vue.filter("priceToShousand", val => {
  if (val) {
    return parseFloat(val / 10000).toFixed(2) + "万元";
  } else {
    return "-";
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
