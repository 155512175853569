/*
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-19 17:09:12
 * @LastEditors  : min
 * @LastEditTime : 2020-12-25 21:13:05
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    redirect: { name: "reportManage" },
    children: [
      {
        path: "/reportManage",
        name: "reportManage",
        component: () => import("../views/ReportManage.vue")
      },
      {
        path: "/userManage",
        name: "userManage",
        component: () => import("../views/UserManage.vue")
      },
      {
        path: "/bannerManage",
        name: "bannerManage",
        component: () => import("../views/BannerManage.vue")
      },
      {
        path: "/carManage",
        name: "carManage",
        component: () => import("../views/CarManage.vue")
      },
      {
        path: "/buyManage",
        name: "buyManage",
        component: () => import("../views/BuyManage.vue")
      }
      // ,
      // {
      //   path: "/activityManage",
      //   name: "activityManage",
      //   component: () => import("../views/ActivityManage.vue")
      // }
    ]
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
