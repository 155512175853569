/*
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-19 17:09:12
 * @LastEditors  : min
 * @LastEditTime : 2020-12-23 14:09:39
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let _userInfo = {};
if (sessionStorage.getItem("_wx_carwins_user_")) {
  _userInfo = JSON.parse(sessionStorage.getItem("_wx_carwins_user_"));
}

export default new Vuex.Store({
  state: {
    userInfo: {
      userId: _userInfo.id,
      userName: _userInfo.nickname
    }
  },
  mutations: {},
  actions: {},
  modules: {}
});
